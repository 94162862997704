import React from 'react'
import { Link } from 'gatsby'
import tw from 'tailwind.macro'
import styled from 'styled-components'
import theme from '../../config/theme'
import "../components/layout.css"

import SEO from '../components/seo'
import Logo from '../components/logo'
import { SiteTitle, Title } from '../components/titles'

const Wrapper = styled.div`
  ${tw`m-2 flex`};
  min-height: 100vh;
`

const Box = styled.div`
  ${tw`p-5 md:p-16 flex flex-wrap items-center flex-col`}
  border: .1rem solid ${theme.colors.primary};
  max-width: 40rem;
  width: 100%;
  margin: auto;
  border-radius: 1rem;
  text-align: center;
  a svg {
    margin-bottom: 2rem;
  }
`
const Registro = () => (
  <>
    <SEO title="Registro" description="Registro Jornada10 - Empieza ahora" />
    <Wrapper>
      <Box>
        <Logo/>
        <SiteTitle>Jornada</SiteTitle>
        <Title>¡Gracias!</Title>
        <p>Pronto nos pondremos en contacto contigo</p>
        <Link>Volver</Link>
      </Box>
    </Wrapper>
  </>
)

export default Registro